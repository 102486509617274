.MainFAQ {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.main__faq-title {
    margin-top: 120px;

}

@media (max-width: 1200px) {
    .MainFAQ.container {
        align-items: center;
        flex-direction: column;
    }
    
    .main__faq-title {
        width: 320px;
        text-align: center;
        margin-top: 60px;
        text-align: center;
    }
}