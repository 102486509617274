.reasons__section {
    padding-top: 120px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #00030F;
    width: 100%;
  }

.reasons__section.light {
  background: #EBF3FF;
  }
  
  .reasons__section-wrapper {
    width: 1200px;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
  }
  
  
  .reasons {
    display: flex;
    flex-direction: column;
    gap: 45px;
  }
  
  .reasons__image {
    width: 496px;
    height: 770px;
    flex-shrink: 0;
    border-radius: 40px;
    object-fit: cover;
  }
  
  
  @media (max-width: 1200px) {
    .reasons__section {
      margin-top: 0px;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  
    .reasons__title {
      width: 320px;
    }
    
    .reasons__section-wrapper {
      width: 320px;
      margin-top: 30px;
      display: flex;
      justify-content: unset;
      flex-direction: column;
      align-items: center;
      position: relative;
    }
    
    .reasons {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    
    .reasons__image {
      margin-top: 30px;
      width: 320px;
      height: 400px;
      border-radius: 40px;
    }
    
  }