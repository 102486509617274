.footer {
    margin-top: 120px;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    background: #00030F;
}

.footer.light {
    background: #EBF3FF;
}

.footer__wrapper {
    width: 1200px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer__logo {
    display: flex;
    align-items: center;
    gap: 15px;
}

.footer__logo-image {
    width: 60px;
    height: 60px;
}

.footer__logo-text {
    font-size: 18px;
    color: var(--brand-gold);
}

.footer__logo-text.light {
    color: var(--brand-blue);
}

.footer__tel {
    color: white;
    font-size: 18px;
}

.footer__tel.light {
    color: black;
}

.footer__contacts {
    display: flex;
    align-items: center;
    gap: 60px;
}

.footer__links {
    display: flex;
    gap: 30px;
}

.footer__link-icon {
    width: 40px;
    height: 40px;
    transition: .3s;
}

.footer__link-icon:hover {
    transition: .3s;
    transform: scale(1.2);
}

.logo-num-mobile {
    display: none;
}

.footer__links-mobile {
    display: none;
}

@media (max-width: 1199px) {
    .footer {
        margin-top: 60px;
        width: 100%;
        height: 167px;
        display: flex;
        justify-content: center;
        background: #00030F;
    }

    .footer__wrapper {
        width: 100%;
        height: 167px;
        display: flex;
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
    }
    .footer__logo {
        display: none;
    }
    .footer__logo-image {
        width: 50px;
        height: 50px;
    }
    .logo-num-mobile {
        margin-top: 20px;
        width: 100%;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .footer__contacts {
        display: none;
    }
    .footer__links-mobile {
        padding: 0px 20px;
        margin-top: 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .footer__link, .footer__link-icon {
        width: 57px;
        height: 57px;
    }

}