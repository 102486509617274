.person__info-mobile-main-image {
  width: 320px;
  height: 360px;
  flex-shrink: 0;
  border-radius: 30px;
  object-fit: cover;
}

.person__info-mobile-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.person__info-mobile-main-name {
    margin-top: 20px;
    font-size: 21px;
    text-align: center;
}

.person__info-mobile-main-job  {
    margin-top: 5px;
    font-size: 14px;
    color: var(--brand-gold);
    text-align: center;
}


.person__info-mobile-collapse {
    margin-top: 16px;
    overflow: hidden;
    transition: .5s;
}

.person__info-mobile-main-more {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.person__info-mobile-main-more-triangle {
    transition: .3s;
}

.person__info-mobile-main-more-triangle.open {
    transform: rotate(180deg);
}