.filada-category {
    padding-top: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
}
  
.category {
  padding: 80px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #00030F;
    cursor: pointer;
}

.category.light {
  background: #EBF3FF;
}
  
  .category-title {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    padding: 0;
    height: 80px;
    display: flex;
    align-items: center;
  }
  
  .subcategories {
    width: 1200px;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
  
  
  .subcategory-title {
    width: 100%;
    background-color: inherit;
    color: rgba(255, 255, 255, 0.85);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: 24px;
    height: 79px;
    cursor: pointer;
  }

  .subcategory-title.light {
    color: rgba(0, 0, 0, 0.85);
  }

  .subcategory-title {
    width: 100%;
    background-color: inherit;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: 24px;
    height: 79px;
    cursor: pointer;
  }
  
  .services {
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
  

  .accordion__service {
    width: 100%;
    padding: 0;
}

.accordion-service-item {
    width: 1200px;
    position: relative;
    padding: 0px 0px 0px 0px;
    list-style-type: none;
}


.accordion-service-header {
    width: 100%;
    background-color: inherit;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: 24px;
    height: 79px;
    cursor: pointer;
}

.accordion-service-body {
    height: 80px;
    padding: 0px 20px;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-service-body:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.20);
}

.accordion-service-body.light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.50);
}

.accordion-service-body.light:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.50);
}

.accordion-service-collapse {
    height: 0px;
    overflow: hidden;
    transition: height .5s;
    overflow: hidden;
    -webkit-transition: height .5s; 
    
}

.accordion-service-collapse.open {
   height: auto;
   width: 12px;
   height: 12px;
}

.tirangle__prices-category {
  margin-left: 10px;
  transition: .5s;
  display: flex;

}

.tirangle__prices-category--open {
  margin-left: 10px;
  transform: rotate(180deg);
}

.tirangle__prices-subcategory {
  margin-top: 4px;
  margin-left: 10px;
  transition: .5s;
  display: flex;
}

.tirangle__prices-subcategory--open {
  margin-left: 10px;
  transform: rotate(180deg);
}

.accordion-service-body-service {
  width: 800px;
}

@media (max-width: 1200px) {

  .filada-category {
    padding-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.subcategory-title {
  font-size: 21px;
  text-align: center;
  height: unset;
}

.category-title {
  width: 320px;
  display: flex;
  justify-content: center;
}

.services {
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  margin-left: 0px;
  width: 320px;
}

.category {
  padding: 40px 0px;
}


.subcategory {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subcategories {
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.subcategories.nocomplicated {
  gap: 0px;
}

  .accordion__service {
      margin-top: 0px;
      width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-inline-start: 0px;
      gap: 40px;

  }

  .accordion__service.notcomplicated {
    gap: 0px;
  }

  .accordion-service-item {
      width: 320px;
  }

  .accordion-service-header {
      width: 100%;
      background-color: inherit;
      color: white;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      font-size: 21px;
      height: unset;
      cursor: pointer;
      text-align: center;
      padding-inline: 0px;
      padding-block: 0px;
  }

  .accordion-service-body {
      margin-top: 0px;
      margin-left: 0px;
      text-align: left;
      padding: 0px 5px;
      color: var(--white-80);
      width: 320px;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      height: 114px;
  }

  .accordion-service-body:first-child {
    margin-top: 25px;
  }

  .tirangle__prices-category {
    margin-left: 8px;
    transition: .5s;
    display: flex;
  
  }
  
  .tirangle__prices-category--open {
    margin-left: 8px;
    transform: rotate(180deg);
  }
  
  .tirangle__prices-subcategory {
    margin-top: 4px;
    margin-left: 8px;
    transition: .5s;
    display: flex;
  }
  
  .tirangle__prices-subcategory--open {
    margin-left: 8px;
    transform: rotate(180deg);
  }

  .accordion-service-body-service {
    width: 310px;
  }
  
}