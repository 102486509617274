.contacts__page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.contacts__wrapper {
  width: 1200px;
  padding: 80px 0px;
  display: flex;
}

.contacts__adress {
  width: 400px;
  height: 163px;
  border-right: 1px solid rgba(217, 217, 217, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.contacts__adress.light {
  border-right: 1px solid var(--brand-blue);
}

.contacts__connect {
  width: 400px;
  height: 163px;
  border-right: 1px solid rgba(217, 217, 217, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.contacts__connect.light {
  border-right: 1px solid var(--brand-blue);
}

.contacts__time {
  width: 400px;
  height: 163px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  align-items: center;
}

.adress,
.contact__tel,
.mail,
.time,
.days {
  font-size: 24px;
  color: white;
}

.adress.light,
.contact__tel.light,
.mail.light,
.time.light,
.days.light {
  color: rgba(0, 0, 0, 0.85);
}

.map {
  width: 100%;
  height: 600px;
}

@media (max-width: 1199px) {
  .contacts__wrapper {
    width: 320px;
    padding: 33px 0px 60px 0px;
    display: flex;
    flex-direction: column;
  }

  .contacts__adress {
    width: 320px;
    height: 122px;
    border-top: 1px solid rgba(217, 217, 217, 0.3);
    border-right: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .contacts__adress.light {
    border-top: 1px solid var(--brand-blue);
    border-right: none;
  }

  .contacts__connect {
    width: 320px;
    height: 122px;
    border-top: 1px solid rgba(217, 217, 217, 0.3);
    border-right: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .contacts__connect.light {
    border-top: 1px solid var(--brand-blue);
    border-right: none;
  }

  .contacts__time {
    width: 320px;
    height: 122px;
    border-top: 1px solid rgba(217, 217, 217, 0.3);
    border-right: none;
    border-bottom: 1px solid rgba(217, 217, 217, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .contacts__time.light {
    border-top: 1px solid var(--brand-blue);
    border-right: none;
    border-bottom: 1px solid var(--brand-blue);
  }

  .adress,
.contact__tel,
.mail,
.time,
.days {
  font-size: 21px;
}

}
