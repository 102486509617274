.cover {
  width: 100%;
  height: 550px;
  position: relative;
  display: flex;
  justify-content: center;
}

.cover__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 2;
}

.cover__description {
  margin-top: 355px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: white;
  position: static;
  z-index: 2;
}

@media (max-width: 1199px) {
  .cover {
    width: 100%;
    height: 460px;
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  .cover__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 2;
  }
  
  .cover__description {
    margin-top: 270px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: white;
    position: static;
    z-index: 2;
  }
}