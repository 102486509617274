.cover__logo {
  position: absolute;
  top: 186px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.cover__logo-title {
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 9.6px;
}

.cover__logo-description {
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.35px;
  text-align: center;
}

@media (max-width: 1199px) {
  .cover__logo {
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }

  .cover__logo-title {
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 7px;
  }

  .cover__logo-description {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
  }
}
