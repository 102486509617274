.modal__form {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: -2;
  transition: 0.3s;
  cursor: pointer;
}

.modal__form.active {
  opacity: 1;
  z-index: 4;
}

.modal__form-wrapper {
  margin-top: 5px;
  width: 747px;
  height: 444px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #060e2b;
  position: relative;
  cursor: default;
}

.modal__form-wrapper.light {
  background: #dae7fa;
}

.modal__form-body {
  padding: 80px 114px 60px 114px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__form-body.hidden {
  display: none;
}

.response-body {
  opacity: 0;
  z-index: -2;
}

.response-body.visible {
  margin-top: 40px;
  opacity: 1;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.response-image {
  width: 150px;
  height: 150px;
}

.response-title {
  margin-top: 25px;
  font-size: 42px;
}

.response-description {
  font-size: 24px;
  margin-top: 14px;
  width: 466px;
  text-align: center;
}

.modal__form-body-title {
  font-size: 42px;
}

.modal__form-body-description {
  margin-top: 14px;
  text-align: center;
  font-size: 24px;
}

.modal__form-form {
  margin-top: 40px;
  width: 547px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px 47px;
}

.modal__form-input {
  width: 250px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  border: none;
  outline: none;
  padding-left: 18px;
}

.modal__form-input.error {
  border: 1px solid red;
}

.modal__form-button {
  font-size: 20px;
  margin-left: 123px;
  width: 300px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--brand-gold);
  border: none;
  color: white;
}

.modal__form-button.light {
  background: var(--brand-blue);
}

.close__icon-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.brand-span {
  color: var(--brand-gold);
}

.brand-span.light {
  color: var(--brand-blue);
}

@media (max-width: 1199px) {
  .modal__form-wrapper {
    width: 320px;
    height: 530px;
  }

  .modal__form-body {
    padding: 50px 20px 60px 20px;
  }

  .modal__form-body-title {
    font-size: 32px;
  }

  .modal__form-body-description {
    margin-top: 10px;
    text-align: left;
    font-size: 21px;
  }

  .modal__form-form {
    margin-top: 30px;
    width: 280px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px 0px;
  }

  .modal__form-input {
    width: 280px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    border: none;
    outline: none;
    padding-left: 18px;
  }

  .modal__form-button {
    font-size: 20px;
    margin-left: 0px;
    width: 280px;
  }

  .response-body.visible {
    margin-top: 70px;
    opacity: 1;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .response-title {
    width: 280px;
    text-align: center;
    margin-top: 30px;
    font-size: 26px;
  }

  .response-description {
    font-size: 21px;
    margin-top: 14px;
    width: 280px;
    text-align: center;
  }
  
}
