.service__prices {
    padding: 80px 0px;
    width: 100%;
    background: #00030F;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
}

.service__prices.light {
    background: #EBF3FF;
}

.service__prices-wrapper {
    width: 1200px;
}

@media (max-width: 1200px) {
    .service__prices {
        padding: 60px 0px;
        gap: 20px;
    }

    .service__prices-wrapper {
        width: 320px;
    }
}

