.header {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 90px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: fixed;
  z-index: 8;
}

.header.light {
  background: rgba(255, 255, 255, 0.7);
}

.header__wrapper {
  width: 1200px;
  display: flex;
  height: 90px;
  align-items: center;
}

.header__logo {
  width: 60px;
  height: 59.657px;
  flex-shrink: 0;
}

.header__nav {
  margin-left: 75px;
  display: flex;
  align-items: center;
  gap: 50px;
}

.header__link:nth-child(2) {
  width: 77px;
}

.header__link-first {
  position: relative;
  display: flex;
  gap: 3px;
  cursor: pointer;
  width: 87px;
}

.header__menu {
  position: absolute;
  background: #060606;
  flex-shrink: 0;
  transition: 0.25s;
  display: none;
}

.header__menu.light.active {
  background: rgba(245, 245, 245, 1);
}

.header__menu.active {
  padding: 0px 20px;
  position: absolute;
  top: 56px;
  left: -15px;
  background: #0c0c0c;
  width: 330px;
  flex-shrink: 0;
  opacity: 1;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px 0px 15px 15px;
}

.header__menu-link {
  color: white;
  text-align: left;
  font-size: 18px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  transition: 0.25s;
}
.header__menu-link.light {
  color: black;
  text-align: left;
  font-size: 18px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  transition: 0.25s;
}

.header__menu-link:first-child {
  border-top: none;
}

.header__menu-link:hover {
  color: var(--brand-gold);
}

.header__link {
  color: white;
  font-size: 18px;
  transition: 0.3s;
}

.header__link.light {
  color: black;
  font-size: 18px;
  transition: 0.3s;
}

.header__link:hover {
  color: var(--brand-gold);
}

.header__buttons {
  margin-left: 60px;
  width: 470px;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: flex-end;
}

.header__call-button {
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--brand-gold);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  transition: 0.3s;
}

.header__call-button.light {
  border: 1px solid var(--brand-blue);
  color: black;
}

.header__call-button:hover {
  background-color: var(--brand-gold);
}

.header__call-button.light:hover {
  background-color: var(--brand-blue);
  color: white;
}

.header__taget-button {
  width: 200px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--brand-gold);
  outline: none;
  border: none;
  color: white;
  font-size: 18px;
  transition: 0.3s;
  border: 1px solid var(--brand-gold);
  display: none;
}

.header__taget-button.light {
  background: var(--brand-blue);
  border: 1px solid var(--brand-blue);
}

.header__taget-button:hover {
  background: inherit;
  border: 1px solid var(--brand-gold);
}
.header__taget-button.light:hover {
  background: inherit;
  border: 1px solid var(--brand-blue);
  color: black;
}

.triangle {
  transition: 0.4s;
}

.triangle.active {
  transform: rotate(180deg);
}

.burger__button {
  display: none;
}

@media (max-width: 1199px) {
  .header {
    width: 100%;
    display: flex;
    justify-content: unset;
    height: 80px;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    z-index: 8;
  }

  .header__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    padding: 0px 20px;
  }

  .header__logo {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }

  .header__nav {
    display: none;
  }

  .header__buttons {
    margin-left: 0px;
    width: 290px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
  }

  .header__call-button {
    width: 146px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid var(--brand-gold);
    font-size: 14px;
  }

  .header__taget-button {
    display: none;
  }

  .toggle__header-button-wrapper {
    margin-right: 10px;
  }

  .burger__button {
    display: block;
  }
}
