.service__page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.page__seo-texts {
    width: 1200px;
}