.slider__card {
  width: 340px;
  height: 340px;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
}

.react-player-feedbacks {
  border-radius: 30px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.lazy-load-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.welcome__image {
  object-fit: cover;
  width: 340px;
  height: 340px;
}

@media (max-width: 1199px) {
  .slider__card {
    width: 300px;
    height: 400px;
    flex-shrink: 0;
  }

  .welcome__image {
    width: 300px;
    height: 400px;
    flex-shrink: 0;
  }
}
