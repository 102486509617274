.aboute__team {
    width: 100%;
    padding: 80px 0px;
    background: #00030F;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboute__team.light {
    background: #EBF3FF;
}

.aboute__team-wrapper {
    margin-top: 100px;
    width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 120px;

}

@media (max-width: 1200px) {
    .aboute__team {
        width: 100%;
        padding: 60px 0px;
        background: #00030F;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .aboute__team-wrapper {
        margin-top: 40px;
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    
    }
}